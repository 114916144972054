import React, {useCallback, useState} from 'react';
import HomeSideBonus from './home-side-bonus';
import HomeService from './home-service';
import HomeChat from './home-chat';
import {useLogin} from '@/utils/state-hooks';
import {useFocusEffect} from '@react-navigation/native';
import {getTaskCount} from '@/pages/promotions/service';
import {goTo} from '@/utils';

interface HomeSideProps {
  hasService?: boolean;
  chatImg?: string;
}

const HomeSide = ({hasService, chatImg}: HomeSideProps) => {
  const login = useLogin();
  const [taskCount, setTaskCount] = useState(0);
  useFocusEffect(
    useCallback(() => {
      if (!login) {
        return;
      }
      getTaskCount().then(data => {
        setTaskCount(data);
      });
    }, [login]),
  );
  return (
    <div className="absolute bottom-[6rem] right-[1.375rem] z-10 flex flex-col items-center">
      {!!chatImg && <HomeChat img={chatImg} />}
      <HomeSideBonus
        count={taskCount}
        onClick={() => goTo('PromotionsV2', {tabKey: 'bonus'})}
      />
      {!!hasService && <HomeService />}
    </div>
  );
};

export default HomeSide;
