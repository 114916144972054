import React, {useEffect, useMemo, useState} from 'react';
import {ActivityDetailResponse, ActivityProgressInfoResponse} from '../../type';
// import globalStore from '@/services/global.state';
import {Button, Image} from 'antd-mobile';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {goBack, replace, replaceByUri} from '@/utils';
import {useTranslation} from 'react-i18next';
import styles from '../common.module.scss';
import {IUserInfo, postUserInfo} from '@/services/global.service';
import {useLogin} from '@/utils/state-hooks';
import RewardsCard from './rewards-card';
import CumulateHeaderCard from './cumulate-header-card';
import dayjs from 'dayjs';
import {CountDown} from '../common';

interface VipRechargeTemplateData {
  backgroundPic?: string;
  backgroundColor?: string;
  eventRule?: string;
  buttonLinear?: string;
  eventRewards?: string;
  redirectUrl?: string;
}

const VipRecharge = () => {
  const [data, setData] = useState<ActivityDetailResponse>();
  const [user, setUser] = useState<IUserInfo>();
  const [progress, setProgress] = useState<ActivityProgressInfoResponse>();
  const login = useLogin();
  useEffect(() => {
    if (login) {
      postUserInfo().then(res => setUser(res));
    }
  }, [login]);
  // const lang = globalStore.lang;
  useEffect(() => {
    const _data = localStorage.getItem('promotion-detail-v2');
    if (_data) {
      try {
        setData(JSON.parse(_data));
      } catch (error) {
        console.info('parse detail error');
      }
    }
    const _progress = localStorage.getItem('promotion-progress-v2');
    if (_progress) {
      try {
        setProgress(JSON.parse(_progress));
      } catch (error) {
        console.info('parse progress error');
      }
    }
  }, []);
  const templateData = useMemo<VipRechargeTemplateData>(() => {
    if (!data) {
      return {};
    }
    try {
      let json = data.templateVariableJson || '{}';
      while (typeof json === 'string') {
        json = JSON.parse(json);
      }
      return json;
    } catch (error) {
      console.info('parse templateVariableJson error');
      return {};
    }
  }, [data]);
  const calcActivityTime = useMemo(() => {
    return (
      dayjs(data?.activityInfo?.startTime).format('DD/MM YYYY') +
      ' - ' +
      dayjs(data?.activityInfo?.endTime).format('DD/MM YYYY')
    );
  }, [data]);
  const award = useMemo(() => {
    if (!progress) {
      return data?.awardList?.[0] || null;
    }
    const _awardList = Array.from(data?.awardList || []);
    _awardList.sort((a, b) => +(a.minAmount || 0) - +(b.minAmount || 0));
    if (progress.progressValue) {
      for (const _award of _awardList) {
        if (+(_award.minAmount || 0) > +(progress.progressValue || 0)) {
          return _award;
        }
      }
      return _awardList[_awardList.length - 1];
    }
    return data?.awardList?.[0] || null;
  }, [progress, data]);
  const isCumulate = useMemo(() => {
    return data?.activityInfo?.activityType?.includes('CUMULATE');
  }, [data]);
  const handlePlay = () => {
    if (!login) {
      replace('Login');
    } else {
      if (data?.activityInfo?.templateKey?.split('_')[1] === 'RECHARGE') {
        replace('Recharge');
      } else if (
        data?.activityInfo?.templateKey?.split('_')[1] === 'WITHDRAW'
      ) {
        replace('Withdraw');
      } else if (data?.activityInfo?.templateKey?.split('_')[1] === 'BET') {
        replaceByUri(templateData.redirectUrl || 'route:Index?screen=Home');
      }
    }
  };

  const hasRewards = useMemo(() => {
    if (!templateData?.eventRewards) {
      return false;
    }
    const removeHTMLData = templateData.eventRewards.replace(/<[^>]*>?/gm, '');
    if (!removeHTMLData || !removeHTMLData.trim()) {
      return false;
    }
    return true;
  }, [templateData.eventRewards]);

  const {t} = useTranslation();
  return (
    <div className="flex flex-col w-auto h-screen overflow-hidden relative font-medium">
      <DetailNavTitle
        onBack={goBack}
        title={t('promotionsV2.details')}
        serverRight
        hideAmount
      />
      <div
        className="flex flex-col flex-1 overflow-hidden relative"
        style={{
          background: templateData.backgroundColor,
        }}>
        <Image
          className="absolute top-0 left-0 w-full"
          src={templateData.backgroundPic}
          fallback={
            <div
              style={{
                background: templateData.backgroundColor,
              }}
            />
          }
        />
        <div className="flex flex-col mt-[9.125rem] flex-1 z-[5] overflow-hidden gap-2">
          <div className="flex flex-row items-center justify-between px-5 py-2">
            <div className="flex flex-col text-white text-xs gap-1">
              <span>{t('promotionsV2.activityTime')}</span>
              <span className="font-bold">{calcActivityTime}</span>
            </div>
            <div className="flex flex-col text-white text-xs items-end gap-1">
              <span>{t('promotionsV2.remainTime')}</span>
              <CountDown
                endTime={data?.activityInfo?.endTime}
                onEnd={() => {}}
              />
            </div>
          </div>

          <div className="flex flex-col flex-1 overflow-auto">
            {!!login && !!award && !!isCumulate && (
              <div className="px-4 pb-2">
                <CumulateHeaderCard
                  vipLevel={user?.level || 0}
                  progressValue={+(progress?.progressValue || 0)}
                  bonusResult={+(award.value || 0)}
                  bonusType={award?.valueType || ''}
                  // 只有当这个是累计次数时，才是这个值，非累计次数不生效
                  // count={+(progress?.progressValue || 0)}
                  totalProgress={+(award.minAmount || 0)}
                  templateKey={data?.activityInfo?.templateKey}
                  onPlay={handlePlay}
                />
              </div>
            )}
            {!!hasRewards && (
              <RewardsCard data={templateData.eventRewards || ''} />
            )}
            <div className="mx-4 bg-white rounded-xl flex flex-col p-3 gap-3">
              <div className="flex flex-row items-center gap-2">
                <div className="w-1 h-3 bg-c1" />
                <div className="text-t1 text-sm font-bold capitalize">
                  {t('promotionsV2.rules')}
                </div>
              </div>
              <div
                className={`flex flex-col flex-1 gap-2 ${styles['event-rule']}`}
                dangerouslySetInnerHTML={{__html: templateData.eventRule || ''}}
              />
            </div>
            <div className="h-[5.75rem] flex-none" />
          </div>
          <div
            className="absolute bottom-0 left-0 right-0 pt-8 pb-3 px-5"
            style={{
              background: templateData.buttonLinear || undefined,
            }}>
            <Button
              className="rounded-full bg-c1 text-white text-base font-bold w-full h-12 flex items-center justify-center before:rounded-full uppercase"
              onClick={handlePlay}>
              {t('promotionsV2.joinNow')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VipRecharge;
