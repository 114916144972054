import Text from '@basicComponents/text';
import Home from './pages/home';
import Me from './pages/me';
import theme from '@style';

import React, {useEffect, useState} from 'react';
import {
  BottomTabBarProps,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import {ImageURISource, Image, View} from 'react-native';
import Casino from './common-pages/casino';
import {NavigatorScreenProps} from '@types';
import LiveCasino from './common-pages/live-casino';
import {Shadow} from 'react-native-shadow-2';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import Scratch from './pages/scratch';
import {useTranslation} from 'react-i18next';
import globalStore from './services/global.state';
import Sports from './common-pages/sports/container';

// import ProxyHome from '@/common-pages/proxy';
// import AgentIcon from './pages/svgs/agent';
// import {toAgentApply} from '@/utils';
// import {toChat} from '@/common-pages/game-navigate';

const isCloseSports = false;
const Tab = createBottomTabNavigator();
import IMWebView from './common-pages/im/im-webview';
import IMChat from './components/svgs/basic/im-chat';

// import IMWebView from './common-pages/im/im-webview';
// import IMChat from './components/svgs/basic/im-chat';
import {goTo} from './utils';
import {homePage} from './config';

export const mainPageList: {
  name: string;
  link: string;
  component: (props: NavigatorScreenProps) => React.JSX.Element;
  img?: ImageURISource;
  activeImg?: ImageURISource;
  unmountOnBlur?: boolean;
  hide?: boolean;
  lazy?: boolean;
  svgIcon?: ({active}: {active: boolean}) => React.ReactNode;
}[] = [
  {
    name: 'Home',
    link: 'index/home',
    component: Home,
    img: require('@assets/icons/home/home.webp'),
    activeImg: require('@assets/icons/home/home-active.webp'),
  },
  {
    name: 'Chat',
    link: 'index/chat',
    component: IMWebView,
    svgIcon: ({active}) => <IMChat active={active} width={24} height={24} />,
    lazy: false,
  },
  // {
  //   name: 'ProxyHome',
  //   link: 'index/proxy-home',
  //   component: ProxyHome,
  //   svgIcon: AgentIcon,
  //   params: {
  //     hideBack: true,
  //   },
  // },
  {
    name: 'Live',
    link: 'index/live',
    component: LiveCasino,
    img: require('@assets/icons/home/live.webp'),
    activeImg: require('@assets/icons/home/live-active.webp'),
  },
  {
    name: 'Casino',
    link: 'index/casino',
    component: Casino,
    img: require('@assets/icons/home/casino.webp'),
    activeImg: require('@assets/icons/home/casino-active.webp'),
  },
  {
    name: 'Scratch',
    link: 'index/scratch',
    component: Scratch,
    img: require('@assets/icons/home/scratch.webp'),
    activeImg: require('@assets/icons/home/scratch-active.webp'),
  },
  {
    name: 'MySports',
    link: 'index/sports',
    component: Sports,
    img: require('@assets/icons/home/sports.webp'),
    activeImg: require('@assets/icons/home/sports-active.webp'),
  },

  {
    name: 'Me',
    link: 'index/me',
    component: Me,
    img: require('@assets/icons/home/me.webp'),
    activeImg: require('@assets/icons/home/me-active.webp'),
  },
];

const CusTab = (props: BottomTabBarProps) => {
  const [imCount, setImCount] = React.useState<number>(0);
  const [token, setToken] = useState('');
  useEffect(() => {
    const countStr = localStorage.getItem('imCount') || '0';
    setImCount(Number(countStr));
    const sub = globalStore.chatNumSubject.subscribe(count => {
      console.log('getCount', count, countStr);
      setImCount(count);
    });
    const tokenSub = globalStore.tokenSubject.subscribe(_token => {
      setToken(_token || '');
    });
    return () => {
      sub.unsubscribe();
      tokenSub.unsubscribe();
    };
  }, []);
  return (
    <Shadow
      containerViewProps={{id: 'tabbar-container'}}
      {...theme.shadow.defaultShadow}
      style={[
        theme.flex.row,
        theme.flex.around,
        theme.background.white,
        theme.fill.fillW,
        theme.shadow.defaultShadow.style,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          height: 56,
        },
      ]}>
      {props.state?.routes.map((route, index) => {
        const {options} = props.descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? (options.tabBarLabel as string)
            : options.title !== undefined
            ? options.title
            : route.name;
        const isFocused = props.state.index === index;
        const onPress = () => {
          if (!isFocused) {
            console.log('options.title', options.title);
            if (route.name === 'MySports' && !isCloseSports) {
              globalStore.openSports.next(undefined);
            } else {
              goTo(route.name);
            }

            if (route.name === 'Chat' && !token) {
              goTo('Login', {
                backPage: homePage,
                sucessPage: homePage,
                sucessPageParams: {
                  screen: 'Chat',
                },
              });
              return;
            }
          }
        };
        return (
          <NativeTouchableOpacity
            key={label}
            activeOpacity={0.8}
            accessibilityRole="button"
            accessibilityState={isFocused ? {selected: true} : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            style={[
              theme.flex.center,
              theme.flex.flex1,
              theme.padding.tbs,
              theme.overflow.visible,
            ]}>
            <View style={[theme.position.rel]}>
              {mainPageList[index].svgIcon ? (
                mainPageList[index].svgIcon!({active: isFocused})
              ) : (
                <Image
                  style={theme.icon.l}
                  source={
                    isFocused
                      ? mainPageList[index].activeImg!
                      : mainPageList[index].img!
                  }
                />
              )}
              {mainPageList[index].name === 'Chat' &&
                !!imCount &&
                imCount > 0 && (
                  <View
                    style={[
                      theme.flex.center,
                      theme.position.abs,
                      {
                        paddingHorizontal: 5,
                        paddingVertical: 2,
                        borderWidth: 1,
                        borderColor: '#FFF',
                        borderRadius: 9999,
                        backgroundColor: '#DC083B',
                        left: 15,
                        top: -4,
                        zIndex: 10,
                      },
                    ]}>
                    <Text
                      fontSize={10}
                      fontFamily="fontDin"
                      blod
                      color={'#FFF'}>
                      {imCount > 99 ? '99+' : imCount}
                    </Text>
                  </View>
                )}
            </View>

            <Text
              blod={isFocused}
              fontSize={10}
              style={{
                color: isFocused
                  ? theme.basicColor.primary
                  : theme.basicColor.dark,
              }}>
              {label}
            </Text>
          </NativeTouchableOpacity>
        );
      })}
    </Shadow>
  );
};

const MainNav = () => {
  const {i18n} = useTranslation();

  return (
    <Tab.Navigator
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={props => <CusTab {...props} />}
      screenOptions={{
        headerShown: false,
      }}
      backBehavior="none">
      {mainPageList.map(v => (
        <Tab.Screen
          key={v.name}
          name={v.name}
          component={v.component}
          options={{
            title:
              v.name === 'MySports'
                ? i18n.t('home.tab.sports')
                : i18n.t(`home.tab.${v.name.toLocaleLowerCase()}`),
            unmountOnBlur: v.unmountOnBlur,
            lazy: v.lazy,
          }}
        />
      ))}
    </Tab.Navigator>
  );
};

export default MainNav;
