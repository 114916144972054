import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {useHomeStyle} from '../home.style';
import React, {useEffect, useRef} from 'react';
import {View, Image, StyleProp, ViewStyle} from 'react-native';
import Text from '@basicComponents/text';
import Tag from '@basicComponents/tag';
import {GoldAreaItem, NoticeMap} from '../home.type';
import {useLotteryModal} from '../lottery.hooks';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {postGetFreeLottery} from '../home.service';
import {goTo, toAgentApply} from '@/utils';
import theme from '@style';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const PromotionsItem = {
  bgImgUrl:
    'https://download.lotteryanna.com/manager/09421856cb05446a9ceaac73121c7bd3.webp',
  groupCode: 'KING_KONG',
  id: 31,
  moduleName: 'Promotions',
  moduleService: '/app/homeGames/getList#categoryId=7',
  moduleType: 'PROMOTIONS',
  parentId: 29,
  sort: 4,
  status: 1,
};

const getNavTag = (num?: number, vipNavsItemTag?: StyleProp<ViewStyle>) =>
  num && num !== 0 ? (
    <Tag style={vipNavsItemTag} content={num < 0 ? '!' : num > 0 ? num : ''} />
  ) : null;

const HomeGoldArea = ({
  noticeMap,
  onNotice,
  list = [],
}: {
  list: GoldAreaItem[];
  noticeMap: NoticeMap;
  onNotice?: () => void;
}) => {
  const {styles} = useHomeStyle();
  if (list[1]?.moduleName === 'Lucky Spin') {
    list.splice(1, 1);
    list.push(PromotionsItem);
  }

  const {i18n} = useTranslation();
  const {calcActualSize, screenWidth} = useScreenSize();
  const doNotice = () => {
    onNotice?.();
  };
  const {renderModal: renderLottery, show: lotteryShow} = useLotteryModal(
    () => {
      globalStore.globalTotal.next({
        type: 'success',
        message: i18n.t('home.tip.copied'),
      });
    },
  );
  const login = useRef<boolean>(false);
  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      login.current = !!token;
      if (token) {
        postGetFreeLottery().then(lotteryInfo =>
          Image.prefetch(lotteryInfo.imgUrl),
        );
      }
    });
    const sub2 = globalStore.doNotices.subscribe(() => {
      doNotice();
    });
    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLotteryClick = async () => {
    if (!login.current) {
      goTo('Login');
      return;
    }
    // goTo('Referral');
    const lotteryInfo = await postGetFreeLottery();
    lotteryShow({
      current: lotteryInfo.num,
      total: lotteryInfo.maxNum,
      backgroundUrl: lotteryInfo.imgUrl,
      shareUrl: lotteryInfo.shareUrl,
      toolTipContent: lotteryInfo.contentStr,
    });
  };

  const onItemClick = (type: string) => {
    if (type === 'Agent') {
      if (!login.current) {
        toAgentApply();
        return;
      }
      if (globalStore.userInfo?.isAgent === 1) {
        goTo('ProxyHome');
      } else {
        toAgentApply();
      }
    } else if (type === 'PROMOTIONS') {
      goTo('PromotionsV2', {tabKey: 'promotion'});
    } else if (type === 'REFER_FRIEND') {
      goTo('Invitation');
    } else {
      handleLotteryClick();
    }
  };

  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.between,
        {
          width: screenWidth,
          marginBottom: calcActualSize(theme.paddingSize.l),
          paddingHorizontal: calcActualSize(theme.paddingSize.l),
        },
      ]}>
      {list.map((item, _i) => {
        return (
          <NativeTouchableOpacity
            key={_i}
            activeOpacity={0.8}
            onPress={() => onItemClick(item.moduleType)}>
            <View
              style={[
                styles.vipNavsItem,
                theme.flex.col,
                theme.flex.center,
                {
                  height: calcActualSize(69),
                },
              ]}>
              <View>
                <Image
                  style={{
                    width: calcActualSize(56),
                    height: calcActualSize(56),
                  }}
                  source={{uri: item.bgImgUrl}}
                />
              </View>
              <Text
                fontFamily="fontInterBold"
                fontSize={11}
                calc
                second
                style={styles.text}>
                {item.moduleName === 'Promotions'
                  ? 'Promotions'
                  : i18n.t(
                      `home.label.${item.moduleType
                        .split('_')
                        .join('-')
                        .toLowerCase()}`,
                    )}
              </Text>
              {item.moduleType === 'FREE_LOTTERY' &&
                getNavTag(noticeMap.FREE_LOTTERY, styles.vipNavsItemTag)}
            </View>
          </NativeTouchableOpacity>
        );
      })}
      {renderLottery}
    </View>
  );
};

export default HomeGoldArea;
