import {
  View,
  StyleSheet,
  LayoutChangeEvent,
  // BackHandler,
  RefreshControl,
  Animated,
} from 'react-native';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {LazyImageBackground} from '@basicComponents/image';
import theme from '@style';
import {
  designToDp,
  goCS,
  goTo,
  navigate,
  toAgentApply,
  toPriceStr,
  useResponsiveDimensions,
  inApp,
  getVersionName,
} from '@utils';
import Button from '@basicComponents/button';
import Text from '@basicComponents/text';
import {
  createVipBadge,
  getVipRender,
  maxVipLevel,
  useVipProgressList,
} from '@businessComponents/vip';
import {
  MeListItem,
  commissionIcon,
  customerServiceIcon,
  languagesIcon,
  moneyIcon,
  notificationsIcon,
  passwordIcon,
  rebateIcon,
  resultHistoryIcon,
  transactionsIcon,
  updateIcon,
} from '@businessComponents/list-item';
import {postVipInfo} from './me.service';
import {
  IUserInfo,
  MessageCountInfo,
  postUserInfo,
  postVipConfig,
} from '@services/global.service';
import {useConfirm, ToastType} from '@basicComponents/modal';
import {useLanguageModal} from '@businessComponents/language';
import {useFocusEffect} from '@react-navigation/native';
import globalStore from '@/services/global.state';
import {toLogin, topBackground} from './me.variable';
import MeHeader from './me-header';
import MeAmount from './me-amount';
import MeUser from './me-user';
import {FadeInView} from '@basicComponents/animations';
import {NoMoreData} from '@/components/basic/default-page';
import Spin from '@/components/basic/spin';
import {useTranslation} from 'react-i18next';
import MeRowMenu from './me-row-menu';
import {getNoticeCheck} from '../home/home.service';
import Tag from '@/components/basic/tag';
import {BasicObject} from '@/types';
import {checkUpdate} from '@/utils/update';
import {setUniqueId} from '@/utils/moengage';
import MePromotions from './me-promotions';

const {
  flex,
  overflow,
  padding,
  paddingSize,
  font,
  margin,
  borderRadius,
  background,
  fontSize,
} = theme;

const topClipHeight = 44;

interface VipInfo {
  level: number;
  diff: number; // 差多少值结束
  nextValue: number; // 该等级结束值
}

/** TODO 单个文件过大,需要拆解 */
const Me = () => {
  const {i18n} = useTranslation();
  const [login, setLogin] = useState<boolean>(false);
  const [vip, setVip] = useState<VipInfo>({
    level: 0,
    diff: 200,
    nextValue: 200,
  });
  const scrollAnim = useRef(new Animated.Value(0)).current;
  const currentVipOption = useMemo(() => getVipRender(vip.level), [vip.level]);
  const nextVipBadge = createVipBadge(vip.level + 1);
  const {width: screenWidth} = useResponsiveDimensions();
  const topBlockWidth = screenWidth - 4 * paddingSize.m;
  const [renderProgress] = useVipProgressList([
    {
      currentBadge: currentVipOption.badge,
      nextBadge: nextVipBadge,
      current: vip.nextValue - vip.diff,
      total: vip.nextValue,
      hasCurrentText: true,
    },
  ]);
  const [user, setUser] = useState<IUserInfo>();
  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm();
  const {renderModal: renderLanguageModal, show: languageShow} =
    useLanguageModal();
  const [showNoMenu] = useState<boolean>(false);
  const [messageCountInfo, setMessageCountInfo] = useState<MessageCountInfo>({
    messageTotalCount: 0,
    sysMessageCount: 0,
    sysUserMessageCount: 0,
  });

  const [userAreaY, setUserAreaY] = useState<number>(0);
  const firstFocus = useRef(true);
  const [pageLoading, setPageLoading] = useState(false);
  const notYetWarning = {
    type: ToastType.warning,
    message: i18n.t('warning.unopen'),
  };
  const [noticeMap, setNoticeMap] = useState<BasicObject>({
    FREE_LOTTERY: 0,
    REBATE: 0,
    LUCKY_SPIN: 0,
  });
  const refresh = useCallback(
    async (token: string | null, showloading = true) => {
      showloading && setPageLoading(true);
      try {
        if (!token) {
          setLogin(false);
          postVipConfig().then(conf => {
            const currentConf = conf.find(c => c.level === 1);
            setVip({
              level: 0,
              diff: currentConf?.recharge || 200,
              nextValue: currentConf?.recharge || 200,
            });
          });
        } else {
          setLogin(true);
          const results = await Promise.allSettled([
            postUserInfo(),
            postVipInfo(),
          ]);
          const [userInfo, vipInfo] = results;
          if (userInfo.status === 'fulfilled') {
            setUser(userInfo.value);
            globalStore.userInfo = userInfo.value;
          }
          if (vipInfo.status === 'fulfilled') {
            const {value: list} = vipInfo;
            for (let i = 0; i < list.length; i++) {
              if (list[i].diff > 0) {
                const current = list[i];
                setVip({
                  level: current.level,
                  diff: current.diff,
                  nextValue: current.amount,
                });
                break;
              }
            }
          }
        }
      } finally {
        setPageLoading(false);
        setRefreshing(false);
      }
    },
    [],
  );
  const doNotice = () => {
    if (login) {
      getNoticeCheck()
        .then(noticeList => {
          const newNoticeMap: BasicObject = {};
          Object.keys(noticeMap).forEach(key => {
            const notice = noticeList.find(item => item.noticeKey === key);
            if (notice) {
              newNoticeMap[key] = notice.noticeCount;
            }
          });
          setNoticeMap(newNoticeMap);
        })
        .catch(() => {
          setNoticeMap({
            FREE_LOTTERY: 0,
            REBATE: 0,
            LUCKY_SPIN: 0,
          });
        });
    } else {
      setNoticeMap({
        FREE_LOTTERY: 0,
        REBATE: 0,
        LUCKY_SPIN: 0,
      });
    }
  };
  const onFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setLogin(!!token);
      refresh(token, firstFocus.current);
      firstFocus.current = false;
      doNotice();
      if (token) {
        globalStore.amountCheckOut.next({});
        globalStore.refreshNotification.next();
      } else {
        setMessageCountInfo({
          messageTotalCount: 0,
          sysMessageCount: 0,
          sysUserMessageCount: 0,
        });
      }
    });
    const msgSub = globalStore.notificationSubject.subscribe(_countInfo => {
      setMessageCountInfo(
        _countInfo || {
          messageTotalCount: 0,
          sysMessageCount: 0,
          sysUserMessageCount: 0,
        },
      );
    });
    return () => {
      sub.unsubscribe();
      msgSub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useFocusEffect(onFocusEffect);

  const handleMoneyLayout = (e: LayoutChangeEvent) => {
    setUserAreaY(e.nativeEvent.layout.y);
  };

  const handleRefresh = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    refresh(globalStore.token);
  };

  const toVip = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Vip');
  };

  const toRecharge = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转recharge
    goTo('Recharge');
  };

  const toWithdraw = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转withdraw
    goTo('Withdraw');
  };

  const toTransfer = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Transfer');
  };

  const toWallet = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Wallet');
  };

  const toResults = () => {
    goTo('Result');
  };

  const toCommission = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转commission
    // goTo('Invitation');
    goTo('ProxyCommissionDetail', {
      userId: user?.userId,
      agentLevel: user?.agentLevel,
    });
  };
  const toRebate = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Rebate');
  };

  const toTransactions = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Transactions');
  };

  const toMyBets = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Bets');
  };

  const toNotify = () => {
    if (!login) {
      goTo('Login');
    } else {
      goTo('Notification');
    }
  };

  const toLanguage = () => {
    languageShow();
  };

  const doLogout = () => {
    // logout
    confirmShow(i18n.t('alert.logout'), i18n.t('alert.sureLogout'), () => {
      setUniqueId('');
      globalStore.token = null;
      globalStore.userInfo = null;
      globalStore.chatNumSubject.next(0);
      localStorage.removeItem('imCount');
      toLogin();
    });
  };

  const toSetPassword = () => {
    if (!login) {
      toLogin();
      return;
    }
    navigate('SetPassword');
  };

  const toAgency = () => {
    if (!login) {
      toAgentApply();
      return;
    }
    if (user?.isAgent === 1) {
      goTo('ProxyHome');
    } else {
      toAgentApply();
    }
  };

  const handleUser = () => {
    if (!login) {
      toLogin();
      return;
    }
    if (showNoMenu) {
      globalStore.globalTotal.next(notYetWarning);
    }
  };
  const [refreshing, setRefreshing] = useState<boolean>(false);
  return (
    <FadeInView>
      {/* TODO 这里的滚动方案需要优化,以及文件过大需要拆分 */}
      <Spin loading={pageLoading} style={[theme.fill.fill]}>
        <Animated.ScrollView
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {y: scrollAnim},
                },
              },
            ],
            {useNativeDriver: true},
          )}
          scrollEventThrottle={1}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                setRefreshing(true);
                refresh(globalStore.token, false);
              }}
            />
          }>
          <View
            style={[
              flex.col,
              flex.end,
              {height: (screenWidth / 375) * (372 - topClipHeight)},
            ]}>
            <LazyImageBackground
              occupancy={'transparent'}
              imageUrl={topBackground}
              width={screenWidth}
              height={(screenWidth / 375) * 372}
              style={[
                padding.lrm,
                styles.topBg,
                overflow.hidden,
                padding.btmm,
              ]}>
              <View style={[padding.m]}>
                {/* 用户块 */}
                <MeUser
                  login={login}
                  user={user}
                  currentVipOption={currentVipOption}
                  onUser={handleUser}
                  showNoMenu={showNoMenu}
                />
                {/* 钱包区域 */}
                <MeAmount
                  login={login}
                  onLayout={handleMoneyLayout}
                  topBlockWidth={topBlockWidth}
                  user={user}
                  onRecharge={toRecharge}
                  onWithdraw={toWithdraw}
                  onRefresh={handleRefresh}
                  onWallet={toWallet}
                />
                {/* VIP区域，直接使用当前的TouchableOpacity组件会导致安卓的vip图标被遮挡，故直接使用原生的 */}
                <NativeTouchableOpacity onPress={toVip}>
                  {currentVipOption.backgroundFn(
                    <View style={[margin.lrm]}>
                      <View
                        style={[
                          flex.row,
                          flex.alignEnd,
                          {marginTop: -paddingSize.l},
                          margin.leftm,
                        ]}>
                        <View style={[margin.rightxs]}>
                          {currentVipOption.sign('small')}
                        </View>
                        {/* 小进度条 */}
                        <View
                          style={[
                            margin.btmxxs,
                            theme.flex.flex1,
                            theme.flex.row,
                            theme.flex.alignEnd,
                          ]}>
                          {renderProgress}
                        </View>

                        <View />
                      </View>
                      {vip.level >= maxVipLevel ? (
                        <View />
                      ) : (
                        <View
                          style={[
                            flex.row,
                            flex.between,
                            flex.centerByCol,
                            padding.lrm,
                            padding.tbxxs,
                          ]}>
                          <View style={[flex.row]}>
                            <Text
                              numberOfLines={2}
                              style={[font.main, styles.opacity]}
                              fontSize={fontSize.xs}>
                              {i18n.t('me.vip.recharge')}{' '}
                              <Text blod>
                                {toPriceStr(vip.nextValue, {fixed: 0})}
                              </Text>{' '}
                              {i18n.t('me.vip.move')} VIP{vip.level + 1}
                            </Text>
                          </View>
                          <Button
                            type="linear-primary"
                            size="xsmall"
                            titleBold
                            title={'Deposit'}
                            onPress={toVip}
                          />
                        </View>
                      )}
                    </View>,
                    topBlockWidth,
                  )}
                </NativeTouchableOpacity>
              </View>
            </LazyImageBackground>
          </View>
          <MeRowMenu
            onRecharge={toRecharge}
            onTransfer={toTransfer}
            onWithdraw={toWithdraw}
            login={login}
            luckyspinFree={noticeMap.LUCKY_SPIN}
            refresh={() => refresh(globalStore.token, false)}
          />
          <MePromotions />
          <View style={[padding.lrl, padding.btmxxl, background.lightGrey]}>
            {/* 列表区域 */}
            <View
              style={[
                background.white,
                padding.lrm,
                padding.tbl,
                borderRadius.m,
                overflow.hidden,
                margin.btml,
              ]}>
              <MeListItem
                icon={require('@assets/icons/me/proxy.webp')}
                title={i18n.t('me.bottom.proxy')}
                rightContent={
                  <View
                    style={[theme.flex.center, theme.padding.lrxs, styles.new]}>
                    <Text fontSize={8} style={[theme.font.white]}>
                      {i18n.t('me.new')}
                    </Text>
                  </View>
                }
                onPress={toAgency}
              />
              <MeListItem
                icon={resultHistoryIcon}
                title={i18n.t('me.bottom.resultHistory')}
                onPress={toResults}
              />

              <MeListItem
                icon={moneyIcon}
                title={i18n.t('me.bottom.myBets')}
                onPress={toMyBets}
              />

              <MeListItem
                icon={transactionsIcon}
                title={i18n.t('me.bottom.myTransactions')}
                onPress={toTransactions}
              />
              <MeListItem
                icon={commissionIcon}
                title={i18n.t('me.bottom.commission')}
                onPress={toCommission}
              />
              <MeListItem
                icon={rebateIcon}
                title={i18n.t('home.label.rebate')}
                onPress={toRebate}
                rightContent={
                  noticeMap.REBATE ? <Tag content={noticeMap.REBATE} /> : null
                }
              />
            </View>
            <View
              style={[
                background.white,
                padding.lrm,
                padding.tbl,
                borderRadius.m,
                overflow.hidden,
                margin.btml,
              ]}>
              <MeListItem
                icon={notificationsIcon}
                title={i18n.t('me.bottom.notify')}
                rightContent={
                  messageCountInfo && messageCountInfo.messageTotalCount ? (
                    <Tag content={messageCountInfo.messageTotalCount} />
                  ) : null
                }
                onPress={toNotify}
              />

              <MeListItem
                icon={passwordIcon}
                title={i18n.t('me.bottom.password')}
                onPress={toSetPassword}
              />

              <MeListItem
                icon={languagesIcon}
                title={i18n.t('me.bottom.lang')}
                onPress={toLanguage}
              />
              <MeListItem
                icon={customerServiceIcon}
                title={i18n.t('me.bottom.customer')}
                onPress={goCS}
              />
              {inApp && (
                <MeListItem
                  icon={updateIcon}
                  title={i18n.t('me.bottom.update')}
                  rightContent={
                    <Text style={[font.secAccent, font.fs]}>
                      {getVersionName()}
                    </Text>
                  }
                  onPress={() => {
                    checkUpdate(true);
                  }}
                />
              )}
            </View>
            {login && (
              <NativeTouchableOpacity onPress={doLogout}>
                <View
                  style={[
                    background.white,
                    padding.lrm,
                    padding.tbl,
                    borderRadius.m,
                    overflow.hidden,
                    margin.btmxxl,
                  ]}>
                  <Text style={[font.second, font.fm, font.bold, font.center]}>
                    {i18n.t('me.bottom.logout')}
                  </Text>
                </View>
              </NativeTouchableOpacity>
            )}
            <NoMoreData text="" />
          </View>
        </Animated.ScrollView>
        <MeHeader
          user={user}
          userAreaY={userAreaY}
          login={login}
          scrollAnim={scrollAnim}
          onUser={handleUser}
          showNoMenu={showNoMenu}
        />
      </Spin>
      {renderConfirmModal}
      {renderLanguageModal}
    </FadeInView>
  );
};

const styles = StyleSheet.create({
  topBg: {
    /** 此处由于裁剪高度是根据宽度按比例换算的，所以这边也需要按照比例换算paddingTop */
    paddingTop: designToDp(topClipHeight) + paddingSize.m,
  },
  opacity: {
    opacity: 0.5,
  },
  lightOpacity: {
    opacity: 0.8,
  },
  bottomNav: {
    height: designToDp(80),
  },
  bottomNavItem: {
    width: designToDp(104),
  },
  new: {
    backgroundColor: '#FF4D4D',
    borderRadius: 20,
    paddingVertical: 2,
  },
});

export default Me;
